var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2 landing-home",class:{ 'mt-10': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-alert',{staticClass:"mb-15 mt-5",attrs:{"icon":"mdi-book-education","color":"#F39200","border":"left","prominent":"","light":""}},[_vm._v(" Stai cercando soluzioni formative per il PNRR - Formazione del personale scolastico per la transizione digitale (DM 66/2023)? Scopri il nostro catalogo dedicato! "),_c('u',{on:{"click":_vm.downloadTrainingCatalog}},[_c('span',{style:({
          color: 'black',
          fontWeight: '600',
          cursor: 'pointer',
        }),attrs:{"href":"https://www.sorint.com/privacy-policy/"}},[_vm._v(" clicca qui ")])])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","order":"2","order-md":"1"}},[_c('h1',{staticClass:"landing-title mb-10",class:{
          'landing-title-mobile': _vm.$vuetify.breakpoint.xsOnly,
        },style:({ textAlign: _vm.$vuetify.breakpoint.smAndDown ? 'center' : '' })},[_c('span',[_vm._v("Novita’ 2025:")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"colored-hacker"},[_vm._v("Tech Journey")])]),_c('h4',{staticClass:"landing-paragraph mb-14",style:({
          textAlign: _vm.$vuetify.breakpoint.smAndDown ? 'center' : '',
          width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : '500px',
        })},[_vm._v(" Pronti per una nuova avventura? A partire dal 2024/25, HackersGen propone le \"Tech Journeys\": percorsi online che uniscono teoria e pratica, personalizzabili secondo interessi e obiettivi di docenti e studenti. Inizia con "),_c('strong',[_vm._v("Full Stack Web Developer")]),_vm._v(": 25 ore su 3 livelli (Back End, Front End, DevOps) e oltre 150 ore di contenuti on-demand. Vieni a provarlo! "),_c('br'),_c('div',{staticClass:"landing-bg-gradient font-karasuma font-weight-bold custom-btn text-center p-4 mt-8",style:({ color: 'black' }),attrs:{"elevation":"2","block":""},on:{"click":function($event){return _vm.goToEvent('/')}}},[_c('span',{staticClass:"body-2 font-weight-bold"},[_vm._v("HackersGen Event: scopri il grande evento onsite della community!")]),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"1.3rem","color":"black"}},[_vm._v("mdi-rocket-launch")])],1)]),_c('h3',{staticClass:"landing-numbers-title mb-5",style:({ textAlign: _vm.$vuetify.breakpoint.smAndDown ? 'center' : '' })},[_vm._v(" I NOSTRI NUMERI ")]),_c('numbers-displayer',{style:({
          center: '',
          margin: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : '',
        })})],1),_c('v-col',{attrs:{"cols":"12","md":"6","order":"1","order-md":"2"}},[_c('PlayerVimeo',{staticClass:"!h-80",class:{ 'mt-16': _vm.$vuetify.breakpoint.smAndDown },attrs:{"video-link":'https://player.vimeo.com/video/1046445141?autoplay=1&muted=1&loop=1'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }