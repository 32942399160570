import authService from "@/services/auth";
import schoolServices from "@/services/schools";
import { truncateUsername } from "@utils/truncateUsername";
import { handleAxiosError, sendNotification } from "@utils/notifications";

const TOKEN_DURATION = 60 * 60 * 1000; // ONE HOUR in milliseconds
const OTP_DURATION = 60 * 5 * 1000; // FIVE MINUTES in milliseconds

const state = () => ({});

const actions = {
  async signUpTeacher(
    _context,
    {
      name,
      surname,
      email,
      password,
      school,
      subject,
      headmaster,
      newsletter,
      privacy,
    }
  ) {
    try {
      await authService.signUpTeacher({
        name,
        surname,
        email,
        password,
        school,
        subject,
        headmaster,
        newsletter,
        privacy,
      });
      sendNotification(
        `Richiesta di registrazione inviata. Attendi email di conferma da parte dell'admin.`,
        `success`
      );
    } catch (err) {
      handleAxiosError(
        `Problema durante la registrazione, controllare i dati inseriti`,
        err
      );
      throw err;
    }
  },
  async signUpTeacherAndSchool(
    _context,
    {
      name,
      surname,
      email,
      password,
      school,
      subject,
      headmaster,
      newsletter,
      privacy,
    }
  ) {
    try {
      await authService.signUpTeacherAndSchool({
        name,
        surname,
        email,
        password,
        school,
        subject,
        headmaster,
        newsletter,
        privacy,
      });
      sendNotification(
        `Richiesta di registrazione inviata. Attendi email di conferma da parte dell'admin.`,
        `success`
      );
    } catch (err) {
      handleAxiosError(
        `Problema durante la registrazione, controllare i dati inseriti`,
        err
      );
      throw err;
    }
  },
  async signUpStudent(
    _context,
    {
      name,
      surname,
      email,
      password,
      classId,
      privacy,
      newsletter,
      can_contact,
      schoolYear,
      secEmail,
    }
  ) {
    try {
      await authService.signUpStudent({
        name,
        surname,
        email,
        password,
        classId,
        privacy,
        newsletter,
        can_contact,
        schoolYear,
        secEmail,
      });
    } catch (err) {
      handleAxiosError(
        `Errore in fase di registrazione. Assicurati che l'email inserita per la registrazione sia uguale a quella dove hai ricevuto l'invito.`,
        err
      );
      throw err;
    }
  },
  async signUpGuest(
    _context,
    { name, surname, email, password, company, privacy, newsletter }
  ) {
    try {
      await authService.signUpGuest({
        name,
        surname,
        email,
        password,
        company,
        privacy,
        newsletter,
      });
    } catch (err) {
      handleAxiosError(
        `Errore in fase di registrazione. In caso l'errore si ripresenti, contatta il supporto.`,
        err
      );
      throw err;
    }
  },
  async forgotPassword(_context, { email }) {
    try {
      const { data } = await authService.forgotPassword(email);
      const truncatedUsername = truncateUsername(data);

      sendNotification(
        `Mail di recupero password inviata a ${truncatedUsername}.\nSarai presto reindirizzato sulla nostra Home Page`,
        `success`,
        10000
      );
    } catch (err) {
      handleAxiosError(`La mail inviata non risulta valida`, err);
      throw err;
    }
  },
  async changePassword(_context, { oldPassword, newPassword }) {
    try {
      await authService.changePassword({ oldPassword, newPassword });
      sendNotification(`Cambio password riuscito`, `success`);
      return;
    } catch (err) {
      handleAxiosError(`Problema nel cambio password. Ricontrollare`, err);
      throw err;
    }
  },
  async resetPassword(_context, { passwordToken, newPassword }) {
    try {
      await authService.resetPassword({ passwordToken, newPassword });
      sendNotification(`Password modificata con successo`, `success`);
      return;
    } catch (err) {
      handleAxiosError(`Errore: password non modificata`, err);
      throw err;
    }
  },
  async login({ commit, dispatch }, { email, password }) {
    try {
      const { data } = await authService.login(email, password);
      commit("AUTH_SUCCESSFULL", data);
      return data;
    } catch (err) {
      commit("AUTH_ERROR");
      handleAxiosError("Problema durante il login, ricontrollare", err);
      throw err;
    }
  },
  async loginOtp({ commit, dispatch }, { email, password }) {
    try {
      const { data } = await authService.login(email, password);
      localStorage.setItem("otp-session-token", data.session_token);
      localStorage.setItem("otp-session-epoch", Date.now());
      localStorage.setItem("otp-email", email);
      setTimeout(() => dispatch("resetOtp"), OTP_DURATION - 10000);
    } catch (err) {
      commit("AUTH_ERROR");
      handleAxiosError("Problema durante il login, ricontrollare", err);
      throw err;
    }
  },
  async resendOtp({ commit, dispatch }, { email }) {
    try {
      const sessionToken = localStorage.getItem("otp-session-token");
      const { data } = await authService.resendOtp(email, sessionToken);
      localStorage.setItem("otp-session-token", data.session_token);
      localStorage.setItem("otp-session-epoch", Date.now());
      setTimeout(() => dispatch("resetOtp"), OTP_DURATION - 10000);
    } catch (err) {
      commit("AUTH_ERROR");
      handleAxiosError(
        "Problema durante la richiesta di un nuovo codice.",
        err
      );
      throw err;
    }
  },
  async verifyOtp({ commit, dispatch }, { otp }) {
    try {
      const sessionToken = localStorage.getItem("otp-session-token");

      const { data } = await authService.verifyOtp(otp, sessionToken);
      if (data.access_level !== 1) {
        commit("AUTH_SUCCESSFULL", data);

        // 30s before expiration, do refresh
        setTimeout(() => dispatch("refreshToken"), TOKEN_DURATION - 30000);
      }
      dispatch("resetOtp");
      return data;
    } catch (err) {
      commit("AUTH_ERROR");
      handleAxiosError("Problema durante il login, ricontrollare", err);
      throw err;
    }
  },
  resetOtp() {
    localStorage.removeItem("otp-session-token");
    localStorage.removeItem("otp-session-epoch");
    localStorage.removeItem("otp-email");
  },
  async loginSorint({ commit, dispatch }, { kcToken }) {
    try {
      commit("AUTH_REQUEST");
      const { data } = await authService.loginSorint(kcToken);
      commit("AUTH_SUCCESSFULL", data);

      // 30s before expiration, do refresh
      setTimeout(() => dispatch("refreshToken"), TOKEN_DURATION - 30000);

      return data;
    } catch (err) {
      commit("AUTH_ERROR");
      handleAxiosError("Problema durante il login, ricontrollare", err);
      throw err;
    }
  },
  logout({ commit, dispatch }) {
    commit("AUTH_LOGOUT");
    dispatch("resetState", {}, { root: true });
  },
  async refreshToken({ commit, state, dispatch }) {
    try {
      const { data } = await authService.refreshToken(state.user.access_token);
      commit("AUTH_REFRESH", data.access_token);
      // 30s before expiration, do refresh
      setTimeout(() => dispatch("refreshToken"), TOKEN_DURATION - 30000);
      return data.access_token;
    } catch (err) {
      commit("AUTH_LOGOUT");
      throw err;
    }
  },
  async setToken({ commit, dispatch }, data) {
    commit("AUTH_SUCCESSFULL", { access_token: data.token, ...data.claims });
    await dispatch("getMyProfile");
  },
  async uploadAvatarImage({ dispatch }, image) {
    try {
      /* Se l'immagine è un tipo di file non supportato */
      if (!image) {
        throw new Error("Inserisci un formato valido");
      }
      await authService.uploadImage(image);
      await dispatch("getAvatar");
      sendNotification(`Foto profilo aggiornata con successo`, `success`);
      return;
    } catch (err) {
      await handleAxiosError(
        `Problema durante la modifica della immagine profilo`,
        err
      );
      throw err;
    }
  },
  async getAvatar({ commit }) {
    try {
      const { data } = await authService.getAvatar();
      commit("SET_AVATAR", data);
      return;
    } catch (err) {
      await handleAxiosError(
        `Problema durante il download della immagine profilo`,
        err
      );
      throw err;
    }
  },
  async getMyProfile({ commit }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await authService.getMyProfile();
      commit("SET_MY_PROFILE", data);
    } catch (err) {
      await handleAxiosError("Errore durante il caricamento del profilo", err);
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async deleteMyAvatar({ commit }) {
    try {
      commit("loading/startLoading", { root: true });
      await authService.deleteMyAvatar();
      sendNotification("Foto rimossa con successo", "success");
    } catch (err) {
      await handleAxiosError(
        "Errore durante la cancellazione dell'immagine",
        err
      );
    } finally {
      commit("loading/stopLoading", { root: true });
    }
  },
  async updateStudentProfile({ commit }, payload) {
    try {
      const { data } = await authService.updateStudentProfile(
        payload.secondaryMail,
        payload.canContact
      );
      commit("UPDATE_SEC_EMAIL", payload.secondaryMail);
      commit("SET_TOKEN", data);
      sendNotification(`Dati aggiornati con successo`, `success`);
      return;
    } catch (err) {
      await handleAxiosError(
        `Si è verificato un errore durante l'aggiornamento`,
        err
      );
      throw err;
    }
  },
  async updateUserInformation({ commit }, payload) {
    commit("loading/startLoading", {}, { root: true });
    try {
      await authService.updateUserInformation(
        payload.name,
        payload.surname,
        payload.id
      );
      sendNotification(`Dati aggiornati con successo`, `success`);
    } catch (err) {
      await handleAxiosError(
        `Si è verificato un errore durante l'aggiornamento dei dati`,
        err
      );
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async updateProfessorProfile({ commit }, secondaryMail) {
    try {
      const { data } = await authService.updateProfessorProfile(secondaryMail);
      commit("UPDATE_SEC_EMAIL", secondaryMail);
      commit("SET_TOKEN", data);
      sendNotification(`Email personale cambiata con successo`, `success`);
      return;
    } catch (err) {
      await handleAxiosError(
        `Si è verificato un errore durante l'aggiornamento`,
        err
      );
      throw err;
    }
  },
  async updateGuestProfile({ commit }, secondaryMail) {
    try {
      const { data } = await authService.updateGuestProfile(secondaryMail);
      commit("UPDATE_SEC_EMAIL", secondaryMail);
      commit("SET_TOKEN", data);
      sendNotification(`Email personale cambiata con successo`, `success`);
      return;
    } catch (err) {
      await handleAxiosError(
        `Si è verificato un errore durante l'aggiornamento`,
        err
      );
      throw err;
    }
  },
  async updateTeacherSchool({ commit }, schoolCode) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await schoolServices.updateTeacherSchool(schoolCode);
      commit("SET_TOKEN", data);
      sendNotification(`Scuola aggiornata con successo`, `success`);
      return data;
    } catch (err) {
      await handleAxiosError("Errore durante il caricamente dei video", err);
      return null;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async isEmailFree({ commit }, email) {
    try {
      commit("loading/startLoading", {}, { root: true });
      const { data } = await authService.isEmailFree(email);
      if (!data.is_free) {
        sendNotification(
          "L'indirizzo email inserito è già associato ad un profilo. Inserire un nuovo indirizzo email",
          "error"
        );
      }
      return data.is_free;
    } catch (err) {
      await handleAxiosError("Errore durante la verifica dell'email", err);
      return false;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
  async confirmVisitorAddress({ commit }, { visitorId }) {
    try {
      commit("loading/startLoading", {}, { root: true });
      await authService.confirmVisitorAddress(visitorId);
      return true;
    } catch (err) {
      await handleAxiosError("Errore durante la conferma della mail", err);
      return false;
    } finally {
      commit("loading/stopLoading", {}, { root: true });
    }
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
